const mode = process.env.NODE_ENV;

function loadConfigs() {

    let scheme      = {};

    let api         = {};

    switch (mode) {

        case "development":

            //api         = require("config/development/api.yaml");

            scheme = {
                app: {
                    name: "nexusAPI",
                    description: "Very Cool app to manage API ressources in your eco-system",
                    //version: version,
                    mode: mode,
                    devtools: true,
                    productionTip: false
                },
                //api: api
            };

            return scheme;

        case "production":

            //api         = require("config/production/api.yaml");

            scheme = {
                app: {
                    name: "nexusAPI",
                    description: "Very Cool app to manage API ressources in your eco-system",
                    //version: version,
                    mode: mode,
                    devtools: true,
                    productionTip: false
                },
                //api: api
            };

            return scheme;

        default:

            return scheme;
    }
}

export default loadConfigs()