<!--eslint-disable-->
<template>

    <div id="app">
        <router-view/>
    </div>

</template>
<!--eslint-disable-->
<script>

    // importing Vuex maps
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "App",
        components: {

            // ...

        },
        created() {

            this.authenticate()

        },
        mounted() {

            // ...

        },
        data() {
            return {

                credentials: {
                    email: 'skf@tune-trafikskole.dk',
                    password: 'iokl+923'
                }

            }
        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated'
            })

        },
        methods: {

            ...mapActions({

                login: 'auth/fetchAuthToken'
            }),

            authenticate: function () {

                this.login(this.credentials)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("API bot authenticated")

                        }

                        //this.populateWebsiteContent(this.token)

                    })

                    .catch(() => {

                        console.log("API bot (catch) failed")

                    })
            }

        }
    };

</script>
<!--eslint-disable-->
<style lang="scss">

    // ...

</style>
