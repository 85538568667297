/* eslint-disable */

/**
 * Custom directive that changes
 * the background color on a div
 */

export default {

    mounted(el) {

        el.style.backgroundColor = "yellow";
    }
};