/* eslint-disable */

/**
 * Importing assets
 */
import moment from 'moment'

/**
 * Register global components here and use them as a plugin in main Vue instance
 */
const globalFilters = {

    // Filter that capitalizes the first letter
    toUpper(value) {

        if (!value) {

            return ''
        }

        return value.toString().charAt(0).toUpperCase() + value.slice(1)
    },

    // Filter that capitalizes the first letter
    capitalize(value) {

        if (!value) {

            return ''
        }

        return value.toString().toUpperCase()
    },

    // Filter that formats datestamps into desired format
    short_date(value) {

        if (value) {

            return moment(String(value)).locale('da').format('DD')
        }
    },

    short_month(value) {

        if (value) {

            return moment(String(value)).locale('da').format('MMM')
        }
    },

    // Filter that formats datestamps into desired format
    date(value) {

        if (value) {

            return moment(String(value)).locale('da').format('DD/MM-YYYY')
        }
    },

    // Filter that formats datestamps into desired format
    dateTime(value) {

        if (value) {

            return moment(String(value)).locale('da').format('DD/MM-YYYY HH:mm')
        }
    },

    // Filter that formats datestamps into desired format
    time(value) {

        if (value) {

            return moment(String(value)).format('HH:mm')
        }
    },

    // Filter that formats datestamps into desired format
    price(value) {

        if (value) {

            return Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK' }).format(value)
        }
    }
}

export default globalFilters;