/* eslint-disable */
import axios, {formToJSON} from "axios"
import router   from ">/router"

const ERROR_MESSAGE = 'ERROR_MESSAGE'

export default {

    namespaced: true,

    state : {
        
        products: [],
        selectedProduct: {},
        error: {}
    },

    actions : {

        async fetchProductLines({ commit }, token) {

            let id = router.currentRoute.value.params.id

            let response = await axios
                
                .get('/get/order/' + id + '/product-lines', {
                    
                    headers: {
                        Authorization: "Bearer " + token
                    }
                })
                
                .then(function (response) {
                    
                    commit('saveProductLines', response.data)
                    commit(ERROR_MESSAGE, null)
                    
                })
                
                .catch(function (error) {

                    commit("saveProductLines", null)

                    if (error.response) {

                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        commit(ERROR_MESSAGE, error.response)

                    } else if (error.request) {

                        // The request was made but no response was received
                        console.log(error.request);

                        commit(ERROR_MESSAGE, error.request)

                    } else {

                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);

                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },

        async updateProductLines({ commit }, payload) {

            let id = router.currentRoute.value.params.id

            let response = await axios

                .post('/update/order/' + id + '/product-lines', payload.products, {

                    headers: {
                        Authorization: "Bearer " + payload.token,
                        "content-type": "application/json",
                    },

                })

                .then(function (response) {

                    console.log("success on line 88");

                    console.log(response.data);
                    console.log(response.status);
                    console.log(response.headers);

                    commit('saveProductLines', response.data)
                    commit(ERROR_MESSAGE, null)

                })

                .catch(function (error) {

                    console.log("catch on line 101");

                    commit("saveProductLines", null)

                    if (error.response) {

                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        commit(ERROR_MESSAGE, error.response)

                    } else if (error.request) {

                        // The request was made but no response was received
                        console.log(error.request);

                        commit(ERROR_MESSAGE, error.request)

                    } else {

                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);

                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },

        async deleteProductLine({ commit }, data) {

            let id = router.currentRoute.value.params.id

            let response = await axios

                .delete('/delete/order/' + id + '/product-lines', {

                    headers: {
                        Authorization: "Bearer " + data.token
                    },

                    params: {
                        "sku": data.sku
                    }

                })

                .then(function (response) {

                    commit('saveProductLines', response.data)
                    commit(ERROR_MESSAGE, null)

                })

                .catch(function (error) {

                    commit("saveProductLines", null)

                    if (error.response) {

                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        commit(ERROR_MESSAGE, error.response)

                    } else if (error.request) {

                        // The request was made but no response was received
                        console.log(error.request);

                        commit(ERROR_MESSAGE, error.request)

                    } else {

                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);

                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },

        async select(context, sku) {
            context.commit("selectProduct", sku);
        }
    },

    getters : {

        error(state) {

            return state.error
        },

        getProductLines(state) {

            return state.products
        },

        getSelectedProduct(state) {

            return state.selectedProduct
        }
    },

    mutations : {

        [ERROR_MESSAGE]: function (state, payload) {
            state.error = payload
        },

        saveProductLines(state, payload) {
            state.products = payload
        },

        selectProduct(state, payload) {
            state.selectedProduct = payload;
        }
    }
}
