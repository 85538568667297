<!--eslint-disable-->
<template>

    <div id="index">
        <div>
            <h3>
                Hello from Vue ('views/home/Index.vue')
            </h3>
        </div>
    </div>

</template>
<!--eslint-disable-->
<script>



export default {
    name: "Index",
    components: {

        // ...

    },
    created() {

        // ...

    },
    mounted() {

        // ...

    },
    data() {
        return {

            // ...

        }
    },
    computed: {

        // ...

    },
    methods: {

        // ...

    }
};

</script>
<!--eslint-disable-->
<style lang="scss">

// ...

</style>
